import React, { useContext, useEffect, useRef } from "react";
// import Booking from "../Booking/Booking";
// import BookingDescription from "../Booking/BookingDescription";
// import GetRealTimeHelp from "../Booking/GetRealTimeHelp";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import BuildingBrands from "../HomePage/BuildingBrands";
import GetInTuch from "../HomePage/GetInTuch";
import HomeBanner from "../HomePage/HomeBanner";
import OurWorkProvided from "../HomePage/OurWorkProvided";
import PhoneNumber from "../HomePage/PhoneNumber";
import PublicDataInformation from "../HomePage/PublicDataInformation";
import RealTimeHelp from "../HomePage/RealTimeHelp";
import TheNextStep from "../HomePage/TheNextStep";
import PageBanner from "../PageBanner/PageBanner";
import { ScrollToCntx } from "../context";
const Home = () => {
    const { serviceRef, projectRef, aboutRef } = useContext(ScrollToCntx);
    useEffect(() => {
        window.scrollTo(0, 0);
        let url = window.location.href;
        if (url.split('#').length > 1) {
            let section = url.split('#')[1];
            // console.log("section", section);
            if (section === 'services') {
                serviceRef?.current?.scrollIntoView()
            }
            if (section === 'projects') {
                projectRef?.current?.scrollIntoView()
            }
            if (section === "about-us") {
                aboutRef?.current?.scrollIntoView()
            }
        }
    }, []);
    return (
        <div>
            <Header />
            <HomeBanner title="Book Meeting" />
            <TheNextStep aboutRef={aboutRef} />
            <BuildingBrands projectRef={projectRef} />
            <OurWorkProvided />
            <PhoneNumber serviceRef={serviceRef} />
            <RealTimeHelp />
            <PublicDataInformation />
            <GetInTuch />
            <Footer />
        </div>
    )
}

export default Home;