import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PageBanner from "../PageBanner/PageBanner";
import Booking from "./Booking";
import BookingDescription from "./BookingDescription";
import GetRealTimeHelp from "./GetRealTimeHelp";

const BookingIndex = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div>
                <Header />
                <PageBanner title="Book Meeting" />
                <BookingDescription />
                <Booking />
                <GetRealTimeHelp />
                <Footer />
            </div>
        </>
    )
}

export default BookingIndex;