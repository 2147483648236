import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const PhoneNumber = ({ serviceRef }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="phone-number" id="services" ref={serviceRef}>
                {/* <div className="phone-number-one"><img src="assets/our-num.png" alt="phone-number" /></div> */}
                <div className="phone-number-two">
                    <img src="assets/main-bg-07.png" alt="phone-number" />
                </div>
                <div className="container">
                    <div className="phone-center">
                        <div className="phone-number-hedding" data-aos="fade-up" data-aos-duration="3000">
                            <h3>Services Offered</h3>
                            {/* <p>
                                Lorem ipsum dolor sit amet erat suscipit dolor pellentesque imperdiet. Tempor lacinia suspendisse nec augue pharetra placerat dictumst ut etiam hendrerit lorem.
                            </p> */}
                        </div>
                    </div>
                    <div className="phone-box-number">
                        <div className="service-add-vector-one"><img src="assets/services-offered-add-vector.png" alt="box-vector-services" /></div>
                        <div className="service-add-vector-two"><img src="assets/services-offered-add-vector.png" alt="box-vector-services" /></div>
                        <div className="row">
                            <div className="col-md-4 mb-3" data-aos="zoom-in" data-aos-duration="1500">
                                <div className="phone-box">
                                    <div className="phone-icon">
                                        <img className="balck-img" src="assets/user-profile-green.svg" alt="phone" />
                                        <img className="witch-img" src="assets/user-profile-white.svg" alt="phone" />
                                    </div>
                                    <div className="phone-hedding-new">
                                        <h3>Phone Numbers & VOIP</h3>
                                    </div>
                                    <div className="phone-content-new">
                                        <p>Call features and Brand-able Numbers that anyone can remember!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3" data-aos="zoom-in" data-aos-duration="2000">
                                <div className="phone-box">
                                    <div className="phone-icon">
                                        <img className="balck-img" src="assets/hend-green.svg" alt="phone" />
                                        <img className="witch-img" src="assets/hend-white.svg" alt="phone" />
                                    </div>
                                    <div className="phone-hedding-new">
                                        <h3>Risk Management & Professional Services</h3>
                                    </div>
                                    <div className="phone-content-new">
                                        <p> Advanced analytics and machine learning to identify potential fraud and mitigate risk.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3" data-aos="zoom-in" data-aos-duration="2500">
                                <div className="phone-box">
                                    <div className="phone-icon">
                                        <img className="balck-img" src="assets/security-green.svg" alt="phone" />
                                        <img className="witch-img" src="assets/security-white.svg" alt="phone" />
                                    </div>
                                    <div className="phone-hedding-new">
                                        <h3>Prospecting</h3>
                                        {/* <div className="coming-soon">
                                            <p>Coming Soon</p>
                                        </div> */}
                                    </div>
                                    <div className="phone-content-new">
                                        <p>Identifying and attracting potential customers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="meet-our">
                        <div className="meet-section-hedding">
                            <div className="meet-content">
                                {/* <p data-aos="fade-up" data-aos-duration="1500">The 3 packages we offer are Business Growth, Business Protection, or Bundle Growth with Protection! </p>
                                <p data-aos="fade-up" data-aos-duration="2000">
                                    The expansion of a business can encompass various strategies such as marketing, domain acquisition, securing memorable phone numbers, and generating sales leads.
                                </p> */}
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    To explore how our services can benefit your business, kindly communicate your objectives to us. Based on your requirements, we can establish a customized plan of action with results that can be paid for as you go, thereby ensuring the success of your company.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="number-bg">
                    <img src="assets/number-bg.png" alt="number-bg" className="img-fluid" />
                </div>
            </section>
        </>
    )
}

export default PhoneNumber;