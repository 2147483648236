import React, { useEffect } from 'react';
import AOS from 'aos';

const ContactDetails = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="container contactdetails">
            <div className='row'>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-3" data-aos="zoom-in" data-aos-duration="2000">
                    <div className='card'>
                        <div>
                            <div className="icon-div">
                                <img className='contactdetails-img' src='assets/email.png' />
                                <img className='with-hover-img' src='assets/phone-with02.png' />
                            </div>
                            <h3 className='contactdetails-heading'>
                                Email Address
                            </h3>
                            <h4 className='contactdetails-subheading'>
                                contact@tnb.net
                            </h4>
                            <h4 className='contactdetails-subheading'>
                                sales@tnb.net
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-3" data-aos="zoom-in" data-aos-duration="2000">
                    <div className='card'>
                        <div>
                            <div className="icon-div">
                                <img className='contactdetails-img' src='assets/hours-green.png' />
                                <img className='with-hover-img' src='assets/hours-white.png' />
                            </div>
                            <h3 className='contactdetails-heading'>
                                Hours
                            </h3>
                            <h4 className='contactdetails-subheading'>
                                Monday - Friday
                            </h4>
                            <h4 className='contactdetails-subheading'>
                                9:00 am - 5:00 pm
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-3" data-aos="zoom-in" data-aos-duration="2000">
                    <div className='card' style={{ height: "100%" }}>
                        <div>
                            <div className="icon-div">
                                <img className='contactdetails-img' src='assets/phone-icon.png' />
                                <img className='with-hover-img' src='assets/phone-with01.png' />
                            </div>
                            <h3 className='contactdetails-heading'>
                                Phone Number
                            </h3>
                            <h4 className='contactdetails-subheading'>
                                Sales: +1 702 222 5555
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactDetails;