import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const HomeBanner = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="home-banner">
                {/* <div className="home-back-one"><img src="assets/main-bg-01.png" alt="back-pt" /></div> */}
                <div className="home-back-two"><img className="move-animation-circale" src="assets/main-bg-02.png" alt="back-pt" /></div>
                {/* <div className="home-back-three"><img src="assets/main-bg-03.png" alt="back-pt" /></div>
                <div className="home-back-four"><img src="assets/main-bg-04.png" alt="back-pt" /></div>
                <div className="home-back-five"><img src="assets/main-bg-05.png" alt="back-pt" /></div>
                <div className="home-back-six"><img src="assets/main-bg-06.png" alt="back-pt" /></div>
                <div className="home-back-seven"><img src="assets/main-bg-07.png" alt="back-pt" /></div>
                <div className="home-back-nine"><img src="assets/main-bg-09.png" alt="back-pt" /></div> */}
                <div className="container">
                    <div className="banner-inner">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <div className="banner-left">
                                    <div className="banner-left-content">
                                        <div className="banner-hedding" >
                                            <h3 data-aos="fade-up"
                                                data-aos-duration="1000">We specialize in business<span> development <div className="bootom-line"></div></span></h3>
                                        </div>
                                        <div className="banner-description" data-aos="fade-up"
                                            data-aos-duration="2000">
                                            <p>
                                                TNB.NET provides private consulting for businesses that market on various channels and want to enchance the branding of the business.Our unique strategies and assets are designed to boost volume and revenue.
                                            </p>
                                        </div>
                                        <div className="banner-btn" data-aos="fade-up"
                                            data-aos-duration="3000">
                                            <button>Read More <img src="assets/home-btn-arrow.png" alt="banner-arrow" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="banner-right">
                                    <div className="banner-vector-one">
                                        <img className="move-animation-cloud" src="assets/banner-vector-01.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-two">
                                        <img className="move-animation-maile-add" src="assets/banner-vector-02.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-three">
                                        <img className="move-animation-doller " src="assets/banner-vector-03.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-four">
                                        <img className="move-animation-maile-add" src="assets/banner-vector-02.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-five">
                                        <img className="move-animation-doller " src="assets/banner-vector-04.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-six">
                                        <img className="move-animation-maile-add" src="assets/banner-vector-02.png" alt="vector-banner" />
                                    </div>
                                    <div className="banner-vector-seven">
                                        <img className="move-animation-zikzek" src="assets/our-num.png" alt="vector-banner" />
                                    </div>
                                    <div className="home-bg-one"><img src="assets/icon.png" alt="bg-pt" className="img-fluid" /></div>
                                    <div className="banner-right-img">
                                        {/* <picture>
                                            <img className="phonetwo img-fluid move-animation" src="assets/phone2.png" alt="home01" />
                                            <img className="phoneone img-fluid move-animation-two" src="assets/phone1.png" alt="home01" />
                                            <img src="assets/home01.png" className="rwes img-fluid" />
                                        </picture> */}
                                        <img src="assets/banner-right-img-new.png" className="rwes img-fluid" />
                                        <img className="phonetwo img-fluid move-animation" src="assets/man.png" alt="home01" />
                                        <img className="phoneone img-fluid move-animation-two" src="assets/man-right.png" alt="home01" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner;