import React, { useEffect } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import "../Booking/booking.css"
import Cal from "../backup/Cal";


const Booking = () => {
    const [value, setValue] = React.useState(() => dayjs('2022-02-01T00:00'));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className="booking">
                <div className="container">
                    <div className="cal-booking">
                        <div className="col-md-6">
                            <div className="cal-hed">
                                <h3>TNB.NET</h3>
                            </div>
                            <div className="main-cal-hedding">
                                <h3>Book a meeting</h3>
                            </div>
                            <div className="time-zoom">
                                <div className="time">
                                    <img src="assets/time.png" alt="img" />
                                    <p>30 min</p>
                                </div>
                                <div className="time add-zoom">
                                    <img src="assets/zoom.png" alt="img" />
                                    <p>Zoom</p>
                                </div>

                            </div>
                            <div className="booking-content">
                                <p>Ultimately, by picking a date and time to speak with the Business Development specialist, you can equip yourself with the knowledge and tools necessary to make informed decisions and drive your business forward. We are pleased to inform you that this demonstration is entirely complementary and incurs no cost to you.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="calender">
                                <Cal />
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <StaticDatePicker
                                        onChange={(newValue) => setValue(newValue)}
                                        value={value}
                                        renderInput={(params) => <TextField {...params} />}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today'],
                                            },
                                        }}
                                    />
                                </LocalizationProvider> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Booking;