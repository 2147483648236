import React from "react";
import "../Booking/booking.css";

const GetRealTimeHelp = () => {
    return (
        <>
            <section className="get-real-time-help">
                <div className="bg-real-one"><img src="assets/real-time-bg-01.png" alt="bg-pt" /></div>
                <div className="bg-real-two"><img src="assets/real-time-bg-02.png" alt="bg-pt" /></div>
                <div className="bg-real-three"><img src="assets/real-time-bg-03.png" alt="bg-pt" /></div>
                <div className="container">
                    <div className="real-time-inner">
                        <div className="row  align-items-center">
                            <div className="col-md-4">
                                <div className="real-time-left">
                                    <div className="real-left-img">
                                        <img src="assets/real-time-left.png" alt="real-time" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="real-time-right">
                                    <div className="row align-items-center">
                                        <div className="col-sm-5 col-md-5">
                                            <div className="real-right-img">
                                                <img src="assets/real-time-right.png" alt="real-time" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7  col-md-7">
                                            <div className="real-time-right-content">
                                                <div className="real-right-hedding">
                                                    <h3>Get real time Help, Data, and Results with our tools</h3>
                                                </div>
                                                <div className="real-right-add-content">
                                                    <p>
                                                        We are a very reliable source when it comes to data. No second guessing when we guarantee our lookups. Do business with confidence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GetRealTimeHelp;