import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const BuildingBrands = ({ projectRef }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section id="projects" className="building-brands" ref={projectRef}>
                {/* <div className="bulding-back-one"><img src="assets/main-bg-03.png" alt="back-bg" /></div> */}
                <div className="bulding-back-two"><img src="assets/building-bg01.png" alt="back-bg" /></div>
                {/* <div className="bulding-back-three"><img src="assets/bg-pt-3.png" alt="back-bg" /></div>
                <div className="bulding-back-four"><img src="assets/main-bg-07.png" alt="back-bg" /></div> */}
                <div className="building-main">
                    <div className="container">
                        <div className="main-text-section">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="building-hedding">
                                        <h3 data-aos="fade-up" data-aos-duration="3000">Types Of Advertisements<span> we work </span></h3>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="bid-p" data-aos="fade-up" data-aos-duration="3000">
                                        <p>Any marketing type listed means you are spending money on advertisement. Regardless of whether you are a small, medium, or large enterprise, spending money needs to make sense and you need to ensure it's done properly for the growth of your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="brands-box-section">
                            <div className="row  justify-content-center">
                                <div className="col-md-4 mb-3-one " data-aos="zoom-in" data-aos-duration="1500">
                                    <div className="barnds-box">
                                        <div className="brands-icon">
                                            <img className="brand-hover" src="assets/presentation-green.svg" alt="barnds" />
                                            <img className="main-barnd-hover" src="assets/presentation-white.svg" alt="hover-barnd" />
                                        </div>
                                        <div className="barnds-name">
                                            <h3>Billboards Presentation</h3>
                                        </div>
                                        <div className="barnds-content">
                                            <p>Creating and implementing effective billboard presentation strategies for our clients.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3-one" data-aos="zoom-in" data-aos-duration="1700">
                                    <div className="barnds-box">
                                        <div className="brands-icon">
                                            <img className="brand-hover" src="assets/socialmedia-green.svg" alt="barnds" />
                                            <img className="main-barnd-hover" src="assets/socialmedia-white.svg" alt="hover-barnd" />
                                        </div>
                                        <div className="barnds-name">
                                            <h3>Social Media Campaign</h3>
                                        </div>
                                        <div className="barnds-content">
                                            <p>Develop and execute successful social media campaigns to increase our clients brand awareness and drive business growth.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3-one" data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="barnds-box">
                                        <div className="brands-icon">
                                            <img className="brand-hover" src="assets/managine-green.svg" alt="barnds" />
                                            <img className="main-barnd-hover" src="assets/managine-white.svg" alt="hover-barnd" />
                                        </div>
                                        <div className="barnds-name">
                                            <h3>Magazine Ads</h3>
                                        </div>
                                        <div className="barnds-content">
                                            <p>Designing and placing impactful magazine ads to help their clients reach their target audience and achieve their marketing goals.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3-one" data-aos="zoom-in" data-aos-duration="2300">
                                    <div className="barnds-box">
                                        <div className="brands-icon">
                                            <img className="brand-hover" src="assets/radio-green.svg" alt="barnds" />
                                            <img className="main-barnd-hover" src="assets/radio-white.svg" alt="hover-barnd" />
                                        </div>
                                        <div className="barnds-name">
                                            <h3> Radio Station Advertisement</h3>
                                        </div>
                                        <div className="barnds-content">
                                            <p>Managing effective radio station advertisement campaigns to help our clients increase their brand recognition and drive sales.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3-one" data-aos="zoom-in" data-aos-duration="2500">
                                    <div className="barnds-box">
                                        <div className="brands-icon">
                                            <img className="brand-hover" src="assets/brand-green.svg" alt="barnds" />
                                            <img className="main-barnd-hover" src="assets/brand-white.svg" alt="hover-barnd" />
                                        </div>
                                        <div className="barnds-name">
                                            <h3>Brand Startup</h3>
                                        </div>
                                        <div className="barnds-content">
                                            <p>Choose us as your business development partner to benefit from our comprehensive expertise, proven track record, and personalized approach to guide and support you in every step of your business startup journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BuildingBrands;