import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';


const PublicDataInformation = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="continer-public-section">
                {/* <div className="continer-public-one"><img src="assets/get-in-01.png" alt="public-bg" /></div>
                <div className="container">
                    <div className="public-data-information">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="public-hedding">
                                    <h3>Public Data & Information</h3>
                                </div>
                            </div>
                            <div className="col-md-6 border-right-new">
                                <div className="public-content">
                                    <p>
                                        Lorem ipsum dolor sit amet erat suscipit dolor pellentesque imperdiet. Tempor lacinia suspendisse nec augue pharetra placerat dictumst ut etiam hendrerit lorem.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-flex">
                    <div className="public-section-card">
                        <div className="without-hover">
                            <div className="img-div-public">
                                <img src="/assets/info1.png" />
                            </div>
                            <div className="outer-center">
                                <h3 className="card-details">
                                    Information about Businesses legitimacy
                                </h3>
                            </div>
                        </div>
                        <div className="with-hover">
                            <div className="relative-hover">
                                <div className="new-cenetr">
                                    <img src="/assets/cover.png" alt="hover-img" className="img-fluid" />
                                </div>
                                <div className="new-cenetr">
                                    <h3 className="hover-title">
                                        Information
                                        about Clients legitimacy
                                    </h3>
                                </div>
                                <div className="btn-div">
                                    <button type="button" className="contact-public-btn">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="public-section-card">
                        <div className="without-hover">
                            <div className="img-div-public">
                                <img src="/assets/info2.png" />
                            </div>
                            <div className="outer-center">
                                <h3 className="card-details">
                                    Information about Phone Numbers
                                </h3>
                            </div>
                        </div>
                        <div className="with-hover">
                            <div className="relative-hover">
                                <div className="new-cenetr">
                                    <img src="/assets/cover.png" alt="hover-img" className="img-fluid" />
                                </div>
                                <div className="new-cenetr">
                                    <h3 className="hover-title">
                                        Information
                                        about Clients legitimacy
                                    </h3>
                                </div>
                                <div className="btn-div">
                                    <button type="button" className="contact-public-btn">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="public-section-card">
                        <div className="without-hover">
                            <div className="img-div-public">
                                <img src="/assets/info2.png" />
                            </div>
                            <div className="outer-center">
                                <h3 className="card-details">
                                    Information
                                    about Clients legitimacy
                                </h3>
                            </div>
                        </div>
                        <div className="with-hover">
                            <div className="relative-hover">
                                <div className="new-cenetr">
                                    <img src="/assets/cover.png" alt="hover-img" className="img-fluid" />
                                </div>
                                <div className="new-cenetr">
                                    <h3 className="hover-title">
                                        Information
                                        about Clients legitimacy
                                    </h3>
                                </div>
                                <div className="btn-div">
                                    <button type="button" className="contact-public-btn">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="public-section-card">
                        <div className="without-hover">
                            <div className="img-div-public">
                                <img src="/assets/info3.png" alt="hover-img" className="img-fluid" />
                            </div>
                            <div className="outer-center">
                                <h3 className="card-details">
                                    Phone Detail Report
                                </h3>
                            </div>
                        </div>
                        <div className="with-hover">
                            <div className="relative-hover">
                                <div className="new-cenetr">
                                    <img src="/assets/cover.png" alt="hover-img" className="img-fluid" />
                                </div>
                                <div className="new-cenetr">
                                    <h3 className="hover-title">
                                        Information
                                        about Clients legitimacy
                                    </h3>
                                </div>
                                <div className="btn-div">
                                    <button type="button" className="contact-public-btn">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="public-section-card">
                        <div className="without-hover">
                            <div className="img-div-public">
                                <img src="/assets/info4.png" alt="hover-img" className="img-fluid" />
                            </div>
                            <div className="outer-center">
                                <h3 className="card-details">
                                    Address lookup
                                </h3>
                            </div>
                        </div>
                        <div className="with-hover">
                            <div className="relative-hover">
                                <div className="new-cenetr">
                                    <img src="/assets/cover.png" alt="hover-img" className="img-fluid" />
                                </div>
                                <div className="new-cenetr">
                                    <h3 className="hover-title">
                                        Information
                                        about Clients legitimacy
                                    </h3>
                                </div>
                                <div className="btn-div">
                                    <button type="button" className="contact-public-btn">Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="marquee-section">
                    <div className="marquee">
                        <div className="scrooling-text">
                            <div className="content  marquee-wrapper">
                                <ul data-aos="zoom-in" data-aos-duration="2000" className="marqueeone" style={{ animationDuration: '57s' }}>
                                    <li>Lead Generation</li>
                                    <li>Voice Solutions</li>
                                    <li>Businesses Legitimacy</li>
                                    <li>Clients legitimacy</li>
                                    <li>Address lookup</li>
                                </ul>
                                <ul data-aos="zoom-in" data-aos-duration="2000" className="marqueeone" style={{ animationDuration: '57s' }}>
                                    <li>Lead Generation</li>
                                    <li>Voice Solutions</li>
                                    <li>Businesses Legitimacy</li>
                                    <li>Clients legitimacy</li>
                                    <li>Address lookup</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PublicDataInformation;