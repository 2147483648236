import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.css";
import Cal from "./backup/Cal";
import { Route, Routes } from 'react-router-dom'
import Home from "./Home";
import Booking from "./Booking/Booking";
import BookingIndex from "./Booking/BookingIndex";
import Contactus from "./Contactus";
import 'aos/dist/aos.css';
import { ScrollToCntx } from './context';

function App() {
  const serviceRef = useRef();
  const aboutRef = useRef();
  const projectRef = useRef();
  return (
    <ScrollToCntx.Provider value={{ serviceRef, aboutRef, projectRef }}>
      <Routes>
        <Route path='/' element={<Suspense fallback={<>Loading...</>}> <Home /></Suspense>} />
        <Route path='/booking-meeting' element={<Suspense fallback={<>Loading...</>}> <BookingIndex /></Suspense>} />
        <Route path='/contact-us' element={<Suspense fallback={<>Loading...</>}> <Contactus /></Suspense>} />
        <Route path='/book-meeting' element={<Suspense fallback={<></>}> <Cal /></Suspense>} />
      </Routes>
    </ScrollToCntx.Provider>
  );
}

export default App;
