import React, { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Footer/Footer.css"
import AOS from 'aos';
import { ScrollToCntx } from "../context";
const Footer = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/booking-meeting')
    }
    const { serviceRef, aboutRef, projectRef } = useContext(ScrollToCntx);

    const move = (page) => {
        navigate('/#' + page);
        if (page === "projects") {
            projectRef.current.scrollIntoView()
        }
        if (page === "services") {
            serviceRef.current.scrollIntoView()
        }
        if (page === "services") {
            aboutRef.current.scrollIntoView()
        }
    }
    const handleRedirectAdminLogin = () => {
        // window.location.href = 'https://admin.tnb.net/';
        window.open('https://admin.tnb.net/');
    }
    return (
        <>
            <footer className="footer-main">
                <div className="footer-top">
                    <div className="footer-top-one"><img src="assets/footer01.png" alt="footer-bg" /></div>
                    <div className="footer-top-two"><img src="assets/footer02.png" alt="footer-bg" /></div>
                    <div className="footer-top-three"><img src="assets/footer03.png" alt="footer-bg" /></div>
                    <div className="footer-top-four"><img src="assets/footer04.png" alt="footer-bg" /></div>
                    <div className="container">
                        <div className="logo-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="footer-logo" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/logo 1.png" alt="footer-logo" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="booking-footer">
                                        <div className="book-inner">
                                            <h3 data-aos="fade-up" data-aos-duration="1500">Ready to get started?</h3>
                                            <div className="book-btn" data-aos="fade-up" data-aos-duration="2500">
                                                <button type="button" onClick={handleNavigate}>Book Meeting</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-menus">
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="fooeter-one">
                                        <div className="foorter-hedding" data-aos="fade-up" data-aos-duration="1000">
                                            <h3>About TNB.NET</h3>
                                        </div>
                                        <div className="footer-content">
                                            <p data-aos="fade-up" data-aos-duration="1500">
                                                Over the decades, phone numbers have constantly been super beneficial, and because of the advantage, the phone number value has constantly increased over time for various patterns. We are constantly dealing with supply and demand issues on highly sought numbers.
                                            </p>
                                            <p data-aos="fade-up" data-aos-duration="2000">
                                                What marketing tool do you know holds its value over time and appreciates?
                                            </p>
                                        </div>
                                        <div className="lern-fot-btn" data-aos="fade-up" data-aos-duration="2500">
                                            <NavLink to="/contact-us">
                                                <button>Learn More <img src="assets/foot-arrow.png" alt="foot-arrow" /></button>

                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-2">
                                    <div className="footer-two">
                                        <div className="footer-hedding" data-aos="fade-up" data-aos-duration="1000">
                                            <h3>Company</h3>
                                        </div>
                                        <div className="fo-menu">
                                            <ul>
                                                <NavLink to="/">
                                                    <li data-aos="fade-up" data-aos-duration="1200">Home</li>
                                                </NavLink>
                                                <li data-aos="fade-up" data-aos-duration="1500" onClick={() => { move('about-us') }}>About Us</li>
                                                <li data-aos="fade-up" data-aos-duration="1700" onClick={() => { move('services') }}>Services</li>
                                                <li data-aos="fade-up" data-aos-duration="2000" onClick={() => { move('projects') }}>Projects</li>
                                                <NavLink to="/contact-us">
                                                    <li data-aos="fade-up" data-aos-duration="2200">Contact Us</li>
                                                </NavLink>
                                                <li data-aos="fade-up" data-aos-duration="2400" onClick={() => { handleRedirectAdminLogin() }}>Admin Login</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="footer-two ne-botton">
                                        <div className="footer-hedding" data-aos="fade-up" data-aos-duration="1000">
                                            <h3>Services</h3>
                                        </div>
                                        <div className="fo-menu">
                                            <ul>
                                                <li data-aos="fade-up" data-aos-duration="1200">Businesses Legitimacy</li>
                                                <li data-aos="fade-up" data-aos-duration="1500">Clients Legitimacy</li>
                                                <li data-aos="fade-up" data-aos-duration="1700">Phone Numbers</li>
                                                <li data-aos="fade-up" data-aos-duration="2000">Address Lookup</li>
                                                <li data-aos="fade-up" data-aos-duration="2200">Phone Detail Report</li>
                                                <li data-aos="fade-up" data-aos-duration="2500">Lead Generation</li>
                                                <li data-aos="fade-up" data-aos-duration="2700">Easy Memorable VANITY</li>
                                                <li data-aos="fade-up" data-aos-duration="3000">Voice Solutions</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="footer-three ne-botton">
                                        <div className="footer-hedding" data-aos="fade-up" data-aos-duration="1000">
                                            <h3>Contact Us</h3>
                                        </div>
                                        <div className="footer-contact">
                                            <div className="email" data-aos="fade-up" data-aos-duration="1500">
                                                <div className="email-icon">
                                                    <img src="assets/mail.png" alt="email" />
                                                </div>
                                                <div className="email-content">
                                                    <p>Email :</p>
                                                    <h4>info@tnb.net</h4>
                                                </div>
                                            </div>

                                            <div className="email" data-aos="fade-up" data-aos-duration="2500">
                                                <div className="email-icon">
                                                    <img src="assets/phone.png" alt="email" />
                                                </div>
                                                <div className="email-content">
                                                    <p>Support :</p>
                                                    <h4>+1 702 222 5555</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <p>Copyright 2023 TNB.NET, All Rights Reserved</p>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="fot-icon">
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                                <path d="M8.0075 8.9995L8.452 6.104H5.6735V4.225C5.6735 3.433 6.0615 2.6605 7.306 2.6605H8.569V0.1955C8.569 0.1955 7.423 0 6.327 0C4.039 0 2.5435 1.387 2.5435 3.8975V6.1045H0V9H2.5435V16H5.6735V9L8.0075 8.9995Z" fill="#98A2A3" />
                                            </svg>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                                <path d="M14 1.34707C13.8084 1.63409 13.5908 1.90289 13.35 2.15007C13.108 2.39883 12.845 2.62633 12.564 2.83007C12.573 2.95553 12.5777 3.08128 12.578 3.20707C12.578 3.78107 12.517 4.34807 12.394 4.90907C12.2714 5.46805 12.0925 6.01317 11.86 6.53607C11.5379 7.27278 11.1123 7.95971 10.596 8.57607C10.1012 9.16547 9.52151 9.67807 8.876 10.0971C8.22821 10.5151 7.52227 10.8352 6.781 11.0471C6.0075 11.2685 5.20655 11.3792 4.402 11.3761C3.62612 11.3771 2.85405 11.2676 2.109 11.0511C1.36229 10.8337 0.651667 10.5075 0 10.0831C0.752172 10.174 1.51489 10.1157 2.24451 9.91151C2.97412 9.70731 3.6563 9.36123 4.252 8.89307C3.80635 8.88683 3.36842 8.77588 2.97357 8.56916C2.57872 8.36244 2.23803 8.06576 1.979 7.70307C1.80233 7.45933 1.66499 7.18938 1.572 6.90307C1.663 6.91707 1.753 6.92907 1.842 6.93807C2.18524 6.97166 2.53173 6.94149 2.864 6.84907C2.53626 6.78444 2.22268 6.66185 1.938 6.48707C1.66216 6.31756 1.41618 6.10369 1.21 5.85407C0.786718 5.34155 0.556689 4.69677 0.56 4.03207V3.99907C0.962 4.22607 1.397 4.34707 1.866 4.36107C1.47493 4.09468 1.15386 3.73794 0.93 3.32107C0.820375 3.11546 0.735452 2.89762 0.677 2.67207C0.55794 2.19475 0.560347 1.69521 0.684 1.21907C0.747 0.976066 0.845 0.745066 0.978 0.526066C1.342 0.977066 1.748 1.38207 2.194 1.73907C3.08041 2.44996 4.10466 2.96923 5.202 3.26407C5.75589 3.41249 6.32418 3.50067 6.897 3.52707C6.86937 3.42038 6.84998 3.31173 6.839 3.20207C6.82773 3.09209 6.82206 2.98162 6.822 2.87107C6.822 2.47407 6.897 2.10107 7.048 1.75307C7.34076 1.06543 7.88836 0.517829 8.576 0.225066C8.92889 0.0740244 9.30915 -0.00257264 9.693 6.59207e-05C10.0867 -0.00184922 10.4765 0.077948 10.8378 0.234412C11.1991 0.390876 11.524 0.620602 11.792 0.909066C12.4336 0.782407 13.0485 0.546296 13.61 0.211066C13.4018 0.877436 12.9535 1.44268 12.352 1.79707C12.9208 1.73096 13.4763 1.5796 14 1.34807V1.34707Z" fill="#98A2A3" />
                                            </svg>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                <path d="M2.68661 12.0014H0.198452V3.98876H2.68661V12.0014ZM1.44103 2.89577C0.645505 2.89577 0 2.23655 0 1.44103C0 1.05884 0.151822 0.692313 0.422068 0.422067C0.692313 0.151822 1.05884 0 1.44103 0C1.82321 0 2.18974 0.151822 2.45999 0.422067C2.73024 0.692313 2.88206 1.05884 2.88206 1.44103C2.88206 2.23655 2.23655 2.89577 1.44103 2.89577ZM11.9989 12.0014H9.51627V8.10096C9.51627 7.17128 9.49742 5.97928 8.22269 5.97928C6.92911 5.97928 6.73066 6.98912 6.73066 8.0341V12.0014H4.24508V3.98876H6.63122V5.08175H6.66594C6.99812 4.4521 7.8095 3.78774 9.01993 3.78774C11.5381 3.78774 12.001 5.44608 12.001 7.5999V12.0014H11.9989Z" fill="#98A2A3" />
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;