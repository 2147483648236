import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const TheNextStep = ({ aboutRef }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="the-next-step" ref={aboutRef}>
                {/* <div className="the-next-step-one"><img src="assets/real-time-bg-01.png" alt="step-bg" /></div>
                <div className="the-next-step-two"><img src="assets/the-next05.png" alt="step-bg" /></div>
                <div className="the-next-step-three"><img src="assets/the-next06.png" alt="step-bg" /></div>
                <div className="the-next-step-four"><img src="assets/main-bg-07.png" alt="step-bg" /></div>
                <div className="the-next-step-five"><img src="assets/the-next07.png" alt="step-bg" /></div> */}
                <div className="the-next-stap-vector-one"><img className="move-animation-maile-add" src="assets/next-stap-vecter01.png" alt="next-step-vector" /></div>
                <div className="the-next-stap-vector-two"><img src="assets/next-stap-vecter02.png" alt="next-step-vector" /></div>
                <div className="the-next-stap-vector-three"><img className="move-animation-maile-add" src="assets/next-stap-vecter01.png" alt="next-step-vector" /></div>
                <div className="container">
                    <div className="real-time-inner">
                        <div className="row  align-items-center ">
                            <div className="col-md-6">
                                <div className="real-time-left">
                                    <div className="real-left-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/what-to-take.png" alt="real-time" className="img-fluid" />
                                    </div>
                                    {/* <div className="profile-call">
                                        <div className="bag-cell">
                                            <div className="profile-img">
                                                <img src="assets/the-next03.png" alt="profile" />
                                            </div>
                                            <div className="midal-name">
                                                <h3>Meet our expert</h3>
                                                <p>Business Strategist</p>
                                            </div>
                                            <div className="last-call">
                                                <img src="assets/the-next04.png" alt="call" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="real-time-right">
                                    <div className="row align-items-center">
                                        {/* <div className="col-sm-5 col-md-5">
                                            <div className="real-right-img">
                                                <img src="assets/the-next02.png" alt="real-time" className="img-fluid" />
                                            </div>
                                        </div> */}
                                        <div className="col-sm-12 col-md-12">
                                            <div className="real-time-right-content">
                                                <div className="real-right-hedding">
                                                    <div className="tag-social" data-aos="fade-up" data-aos-duration="500">
                                                        <p>Social Media</p>
                                                    </div>
                                                    <h3 data-aos="fade-up" data-aos-duration="1000">Want to Take Your Business to The Next Step?</h3>
                                                    <p data-aos="fade-up" data-aos-duration="1500">
                                                        Boost Response Rates up to 40%
                                                    </p>
                                                </div>
                                                <div className="real-right-add-content">
                                                    <p data-aos="fade-up" data-aos-duration="2000">
                                                        Marketing channels, such as social media, print advertising, or TV commercials, businesses can create a consistent and memorable brand message that customers can associate with their
                                                        products or services.</p>
                                                    <p data-aos="fade-up" data-aos-duration="2500">
                                                        Furthermore, businesses can also use custom phone numbers to track their marketing efforts and measure their effectiveness.
                                                    </p>
                                                    <p data-aos="fade-up" data-aos-duration="3000">
                                                        By using different phone numbers for different marketing campaigns, businesses can analyse call volume and other metrics to determine which
                                                        campaigns are driving the most leads and sales.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TheNextStep;