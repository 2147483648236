import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const GetInTuch = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="get-in-tuch">
                <div className="get-in-one"><img src="assets/get-in-01.png" alt="bg-pt" /></div>
                <div className="get-in-two"><img src="assets/get-in-02.png" alt="bg-pt" /></div>
                <div className="container">
                    <div className="get-inner-section">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="get-in-left" data-aos="zoom-in" data-aos-duration="2000">
                                    <img src="assets/get-in-tuch.png" alt="get-in-tuch" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="get-in-right">
                                    <div className="get-in-content" data-aos="fade-up" data-aos-duration="1500">
                                        <h3>Get In Touch</h3>
                                    </div>
                                    <div className="get-in-dex" data-aos="fade-up" data-aos-duration="2000">
                                        <p>Contact a member of our company by Phone, Email, Contact us Section, or the "Book Meeting" tab on our website. We are available Monday - Friday 9:00 am - 5:00 pm Pacific Standard Time.</p>
                                    </div>
                                    <div className="get-in-btn" data-aos="fade-up" data-aos-duration="2500">
                                        <button>Contact Us</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GetInTuch;