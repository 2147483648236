import React, { useEffect, useState } from "react";
// import "./App.css";
// import { gapi } from "gapi-script";
// import { google } from "googleapis";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import axios from "axios";
import './cal.css';
import moment from 'moment'
// const url = 'http://localhost:8000/';
const url = 'https://www.api.tnb.net/';
const TIMEOFFSET = '+05:30';

function Cal() {
    const [value, setValue] = React.useState(() => dayjs(new Date()));
    const [sloats, setSloats] = useState([]);
    const [selectedSloat, setSelectedSloat] = useState(null);
    const [step, setStep] = useState(1);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState({});

    const getSloats = (date) => {
        axios.post(url + `get-sloats`, { date: date }).then((res) => {
            setSloats(res?.data[0].sloatTime);
            console.log("dsxcybh", res?.data[0].sloatTime)
        }).catch((err) => {
            console.log(err);
        })
    }
    const getFormatedValue = (date) => {
        let y = (new Date(date)).getFullYear()
        let m = ((new Date(date)).getMonth() + 1) > 9 ? ((new Date(date)).getMonth() + 1) : ('0' + ((new Date(date)).getMonth() + 1));
        let d = (new Date(date)).getDate() > 9 ? (new Date(date)).getDate() : ('0' + (new Date(date)).getDate())
        return (y + '-' + m + '-' + d);
    }

    const handleBookMetting = () => {
        let validate = true, err = {};
        if (!fullName) {
            err.fullName = 'Please Enter Full Name.';
            validate = false;
        }
        if (!email) {
            err.email = 'Please Enter Email.';
            validate = false;
        } else if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            err.email = 'Please Enter valid Email.';
            validate = false;
        }
        if (!mobile) {
            err.mobile = 'Please Enter Phone Number.';
            validate = false;
        }
        if (!companyName) {
            err.companyName = 'Please Enter Company Name.';
            validate = false;
        }
        setError(err);
        if (validate) {
            // let event = {
            //     'summary': `This is the summary.`,
            //     'description': `This is the description.`,
            //     'start': {
            //         'dateTime': dateTime['start'],
            //         'timeZone': 'Asia/Kolkata'
            //     },
            //     'end': {
            //         'dateTime': dateTime['end'],
            //         'timeZone': 'Asia/Kolkata'
            //     }
            // };
            let selectedDate = selectedSloat.date?.$d;
            selectedDate = new Date(selectedDate);
            let timeObj = selectedSloat;
            let hh = 0;
            let mm = 0
            if (timeObj.time) {
                hh = timeObj.time.split(":")[0];
                mm = timeObj.time.split(":")[1];
                let date = new Date(selectedDate);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                if (month < 10) {
                    month = `0${month}`;
                }
                let day = date.getDate();
                if (day < 10) {
                    day = `0${day}`;
                }
                let hour = date.getHours();
                if (hour < 10) {
                    hour = `0${hour}`;
                }
                let minute = date.getMinutes();
                if (minute < 10) {
                    minute = `0${minute}`;
                }
                let d = new Date();
                d.setFullYear(year);
                d.setMonth(month - 1);
                d.setDate(day);
                d.setHours(hh);
                d.setMinutes(mm.split(' ')[0]);
                d.setSeconds(0);

                // let newDateTime = `${year}-${month}-${day}T${hh}:${mm}:00.000${TIMEOFFSET}`;
                // let cleanedDateString = newDateTime.replace(/ am:00\.000/, '');
                // cleanedDateString = newDateTime.replace(/ pm:00\.000/, '');
                let event = new Date(d);

                let startDate = event;
                // Delay in end time is 30
                let endDate = new Date(new Date(startDate).setMinutes(startDate.getMinutes() + 30));

                let body = {
                    'summary': fullName,
                    'companyName': companyName,
                    'description': `company Name: ${companyName} 
                attendee email:  ${email}`,
                    'start': {
                        'dateTime': startDate,
                        'timeZone': 'Asia/Kolkata'
                    },
                    'end': {
                        'dateTime': endDate,
                        'timeZone': 'Asia/Kolkata'
                    },
                    'email': email,
                    'phone': mobile
                }
                axios.post(url + 'create-metting', body).then((res) => {
                    console.log("test success", res);
                }).catch((err) => {
                    console.log(err);
                })
                setStep(4);
                setEmail('');
                setFullName('');
                setMobile('');
                setCompanyName('');
            }
            // selectedDate.setHours(selectedSloat.time.split(':')[0]);
            // selectedDate.setMinutes(selectedSloat.time.split(':')[1]);


        }
    }

    const handleDeleteEvent = () => {
        let id = 'ebc72tinl1mldr9bnqpf2v4dh8';
        axios.delete(url + 'delete-event/' + id).then((res) => {
            console.log("res delete", res.data);
        }).catch((err) => {
            console.log(err);
        })

    }
    const fomatDateTime = () => {
        let selectedDate = selectedSloat.date?.$d;
        selectedDate = new Date(selectedDate);
        let timeObj = selectedSloat;
        let hh = 0;
        let mm = 0
        if (timeObj.time) {
            hh = timeObj.time.split(":")[0];
            mm = timeObj.time.split(":")[1];
            let date = new Date(selectedDate);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) {
                month = `0${month}`;
            }
            let day = date.getDate();
            if (day < 10) {
                day = `0${day}`;
            }
            let hour = date.getHours();
            if (hour < 10) {
                hour = `0${hour}`;
            }
            let minute = date.getMinutes();
            if (minute < 10) {
                minute = `0${minute}`;
            }
            let d = new Date();
            d.setFullYear(year);
            d.setMonth(month - 1);
            d.setDate(day);
            d.setHours(hh);
            d.setMinutes(mm.split(' ')[0]);
            d.setSeconds(0);

            // let newDateTime = `${year}-${month}-${day}T${hh}:${mm}:00.000${TIMEOFFSET}`;
            // let cleanedDateString = newDateTime.replace(/ am:00\.000/, '');
            // cleanedDateString = newDateTime.replace(/ pm:00\.000/, '');
            let event = new Date(d);
            // console.log("sdxh", d, mm);
            // console.log("sdlnjh", event, d)
            let startDate = event;
            return moment(startDate).format('MMMM Do YYYY, h:mm:ss a');
        }

    }

    return (
        <div className="App">
            {
                step === 1 &&

                <>
                    <div className="nrw-ti-cal">
                        <div className="cal-title">
                            Select Date & Time
                        </div>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <StaticDatePicker
                            onChange={(newValue) => setValue(newValue)}
                            value={value}
                            renderInput={(params) => <TextField {...params} />}
                            componentsProps={{
                                actionBar: {
                                    actions: ['today'],
                                },
                            }}
                            minDate={new Date()}
                        />
                        <div className="div-next-btn">
                            <button type="button" className="next-btn" onClick={() => { setStep(2); getSloats(getFormatedValue(value)) }}>Next</button>

                        </div>
                    </LocalizationProvider>
                </>
            }
            {
                step === 2 && <>
                    <div className="nrw-ti-cal">
                        <div className="cal-title">
                            Select Date & Time
                        </div>
                    </div>

                    <div className="nrw-ti-cal">
                        <div className="sloat-div">
                            {
                                sloats && sloats.map((item, index) => {
                                    if (item.status === 'OPEN') {
                                        return <span className={`time-sloat ${selectedSloat?.time === item.time && 'active'}`} key={index} onClick={() => { setSelectedSloat({ time: item.time, date: value }); }}>{item.time}</span>
                                    } else {
                                        return ''
                                    }
                                })
                            }
                        </div>
                        <div className="confirm-btn-row">
                            <button type="button" className="back-btn" onClick={() => { setStep(1); }} >Back</button>
                            <button type="button" className="next-btn" onClick={() => { selectedSloat !== null && setStep(3); }}>Next</button>
                        </div>
                    </div>
                </>
            }
            {
                step === 3 && <>
                    <div className="pl-4">
                        <div className="cal-title">
                            Your Information
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12 mb-3">
                                    <input type="text" className="contact-form-input" placeholder="Full Name" onChange={(e) => { setFullName(e.target.value); }} value={fullName} />
                                    {error && error?.fullName && <div className="error">{error.fullName}</div>}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-3">
                                    <input type="email" className="contact-form-input" placeholder="Email Address" onChange={(e) => { setEmail(e.target.value); }} value={email} />
                                    {error && error?.email && <div className="error">{error.email}</div>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input type="number" className="contact-form-input" placeholder="Phone Number" onChange={(e) => { setMobile(e.target.value); }} value={mobile} />
                                    {error && error?.mobile && <div className="error">{error.mobile}</div>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <input type="text" className="contact-form-input" placeholder="Company Name" onChange={(e) => { setCompanyName(e.target.value); }} value={companyName} />
                                    {error && error?.companyName && <div className="error">{error.companyName}</div>}
                                </div>
                            </div>
                            <div className="confirm-btn-row">
                                <button type="button" className="back-btn" onClick={() => { setStep(2); }} >Back</button>
                                <button type="button" className="next-btn" onClick={() => { handleBookMetting() }} >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                step === 4 &&
                <>
                    <div className="main-thankyou">
                        <div className="tankyou-list">
                            <div className="thank-you-img">
                                <img src="assets/success.svg" alt="thankyou" />
                            </div>
                            <div className="thank-content">
                                <h3>Booking confirmed</h3>
                                <div className="content-thankyou">
                                    <p>You're booked with Admin.
                                        An invitation has been emailed to you.</p>
                                </div>
                                <div className="last-date">
                                    <h4>{fomatDateTime()}</h4>
                                </div>
                            </div>
                            {console.log("selectedSloat", selectedSloat)}
                        </div>
                    </div>

                </>
            }
            {/* <button type='button' onClick={() => { handleDeleteEvent() }}>Delete Event For Admin</button> */}
        </div>
    );
}

export default Cal;
