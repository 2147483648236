import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Header/Header.css"
import { ScrollToCntx } from "../context";

const Header = () => {
    const [mobileSidebarStatus, setMobileSidebarStatus] = useState(window.innerWidth < 767 ? false : true);
    const [headerOneClass, setHeaderOneClass] = useState("");
    const [headerClass, setHeaderClass] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { serviceRef, aboutRef, projectRef } = useContext(ScrollToCntx);
    useEffect(() => {
        window.onscroll = function () {
            if (window.scrollY > 120) {
                setHeaderOneClass("heder-top-close")
            }
            else {
                setHeaderOneClass("heder-top-open")
            }
            if (window.scrollY > 120) {
                setHeaderClass("header-bg")
            }
            else {
                setHeaderClass("header-transparent")
            }
        };
    }, [window.screenY]);
    // console.log("dsyzxtfghb", windowWidth)
    // Add event listener
    window.addEventListener("resize", () => {
        if (window.innerWidth < 767 && mobileSidebarStatus === true) {
            // console.log("sxzuhnj")
            setMobileSidebarStatus(false);
        } else if (window.innerWidth > 767 && mobileSidebarStatus === false) {
            setMobileSidebarStatus(true);
        }
    });
    const navigate = useNavigate();
    const move = (page) => {
        navigate('/#' + page);
        if (page === "projects") {
            projectRef?.current?.scrollIntoView()
        }
        if (page === "services") {
            serviceRef?.current?.scrollIntoView()
        }
        if (page === "about-us") {
            aboutRef?.current?.scrollIntoView()
        }
    }

    return (
        <>
            <header className={`header-all  ${headerOneClass}`}>
                <div className="container">
                    <div className={`header-main ${headerClass}`}>
                        <div className="logo">
                            <a href="/">
                                <img src="assets/logo 1.png" alt="logo" />
                            </a>
                            <div className="menu-bred">
                                {
                                    mobileSidebarStatus === false &&
                                    <img id="BarsIcon" src="assets/menu.png" className={`${mobileSidebarStatus === true ? "open-nav" : ""}`} onClick={() => { setMobileSidebarStatus(true); document.getElementById('body').style.overflow = 'hidden' }} alt="menu" />
                                }
                            </div>

                        </div>
                        {mobileSidebarStatus === true &&
                            <ul className="menu-main">
                                <div className="close-side">
                                    {
                                        mobileSidebarStatus === true &&
                                        <img className={` ${mobileSidebarStatus === false ? "close-nav" : ""}`} onClick={() => { setMobileSidebarStatus(false); document.getElementById('body').style.overflow = '' }} src="assets/close.png" alt="close" />
                                    }
                                </div>
                                <li>
                                    <NavLink to="/" onClick={() => { document.getElementById('body').style.overflow = '' }}>
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <span onClick={() => { move('about-us') }}>
                                        About Us
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => { move('services') }}>
                                        Services
                                    </span>
                                </li>
                                {/* <li>
                                    <span onClick={() => { move('projects') }}>
                                        Projects
                                    </span>
                                </li> */}
                                <li>
                                    <span onClick={() => { document.getElementById('body').style.overflow = ''; navigate('/contact-us'); }}>
                                        Contact Us
                                    </span>
                                    {/* <NavLink to="/contact-us" onClick={() => { }}>
                                        Contact Us
                                    </NavLink> */}
                                </li>
                                <li className="but-new" onClick={() => { document.getElementById('body').style.overflow = ''; navigate('/booking-meeting'); }}>
                                    Book Meeting
                                    {/* <NavLink to="/booking-meeting" onClick={() => { document.getElementById('body').style.overflow = '' }} className="new-wite">
                                        Book Meeting
                                    </NavLink> */}
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;