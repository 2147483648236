import React, { useEffect } from "react";
import "../HomePage/Home.css"
import AOS from 'aos';

const RealTimeHelp = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="the-next-step o-new-one">
                {/* <div className="the-next-step-one"><img src="assets/real-time-bg-01.png" alt="step-bg" /></div>
                <div className="the-next-step-two"><img src="assets/the-next05.png" alt="step-bg" /></div>
                <div className="the-next-step-three"><img src="assets/the-next06.png" alt="step-bg" /></div>
                <div className="the-next-step-four"><img src="assets/main-bg-07.png" alt="step-bg" /></div>
                <div className="the-next-step-five"><img src="assets/the-next07.png" alt="step-bg" /></div> */}
                <div className="the-next-step-six"><img src="assets/data01.png" alt="step-bg" /></div>
                <div className="the-next-step-seven"><img src="assets/data02.png" alt="step-bg" /></div>
                <div className="the-next-step-eight"><img src="assets/data03.png" alt="step-bg" /></div>
                <div className="container">
                    <div className="real-time-inner">
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-md-6">
                                <div className="real-time-left">
                                    <div className="real-left-img" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src="assets/our-business-protection-left.png" alt="real-time" className="img-fluid" />
                                    </div>
                                    {/* <div className="profile-call">
                                        <div className="bag-cell">
                                            <div className="midal-name">
                                                <p>Real TIme data</p>
                                                <h3>$6.76k</h3>
                                                <p>+ 32%</p>
                                            </div>
                                            <div className="last-call">
                                                <img src="assets/real-time-map.png" alt="call" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="real-time-right">
                                    <div className="row align-items-center">
                                        {/* <div className="col-sm-5 col-md-5">
                                            <div className="real-right-img">
                                                <img src="assets/real-time-help02.png" alt="real-time" className="img-fluid" />
                                            </div>
                                        </div> */}
                                        <div className="col-sm-12  col-md-12">
                                            <div className="real-time-right-content">
                                                <div className="real-right-hedding">
                                                    <div className="tag-social" data-aos="fade-up" data-aos-duration="1500">
                                                        <p>Protection</p>
                                                    </div>
                                                    <h3 data-aos="fade-up" data-aos-duration="2000">Our Business Plan</h3>
                                                </div>
                                                <div className="real-right-add-content">
                                                    <p data-aos="fade-up" data-aos-duration="2500">
                                                        We assure you that all discussions during meetings will be treated with the utmost confidentiality. Rest assured, no information will be shared with competitors or third-party sources.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RealTimeHelp;