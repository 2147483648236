import React from "react";
import "../Booking/booking.css"

const BookingDescription = () => {
    return (
        <>
            <section className="booking-description">
                <div className="bg-pt-one"><img src="assets/bg-pt-1.png" alt="bg-pt" /></div>
                <div className="bg-pt-two"><img src="assets/bg-pt-2.png" alt="bg-pt" /></div>
                <div className="bg-pt-three"><img src="assets/bg-pt-3.png" alt="bg-pt" /></div>
                <div className="bg-pt-four"><img src="assets/bg-pt-4.png" alt="bg-pt" /></div>
                <div className="container">
                    <div className="booking-section">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="booking-left">
                                    <div className="booking-new-heding">
                                        <h3>Pick a Date & Time — Get a 30-Minute Demo</h3>
                                    </div>
                                    <div className="book-des">
                                        <p>During the demo, the specialist will showcase their range of marketing services any questions you may have. This can be a great opportunity to gain a better understanding of how the specialist can help your business achieve its goals and reach new heights.</p>
                                        <p>
                                            With our experience and extensive network of connections, we can provide you with valuable insights and solutions to help you reach a wider audience
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="booking-right">
                                    <div className="booking-points">
                                        <ul>
                                            <li>
                                                Access to specialized knowledge and expertise: We have a deep understanding of the latest trends, best practices, and strategies for growing and scaling businesses.
                                            </li>
                                            <li>
                                                Objective, outside perspective: We, as a business development firm, can offer an objective, outside perspective that can help you see your business in a new light and identify opportunities for improvement.
                                            </li>
                                            <li>
                                                Increased efficiency and productivity: With a solid growth plan in place, you can focus your time and resources on the most impactful strategies for your business.
                                            </li>
                                            <li>
                                                Expanded network and resources: Our vast network of contacts and resources that can be leveraged to help grow your business. We may be able to introduce you to potential partners, investors, or customers, and provide access to valuable tools and technologies.
                                            </li>
                                            <li>Customized approach: Every business is unique, and a business development firm will tailor their approach to your specific needs and goals. They will work with you to develop a customized plan that aligns with your vision and values, and that takes into account your strengths, weaknesses, opportunities, and threats.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default BookingDescription;