import React, { useEffect } from "react";
import "../PageBanner/PageBanner.css"
import AOS from 'aos';

const PageBanner = ({ title }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className="page-banner">
                <div className="container">
                    <div className="banner-hedding" data-aos="zoom-in" data-aos-duration="2000">
                        <h3>{title}</h3>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageBanner;