import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PageBanner from "../PageBanner/PageBanner";
import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";
import './contactus.css'

const Contactus = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <PageBanner title="Contact Us" />
            <ContactDetails />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Contactus;