import React, { useEffect, useState } from 'react';
import axios from "axios";
import AOS from 'aos';

const ContactForm = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    // let url = 'http://localhost:8000/'
    let url = 'https://www.api.tnb.net/'
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [error, setError] = useState({});

    const handelSubmit = () => {
        setError({});
        let validate = true, err = {};
        if (!name) {
            validate = false;
            err.name = 'Please Enter Name.';
        }
        if (!email) {
            validate = false;
            err.email = 'Please Enter Email.';
        } else if (!(email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))) {
            validate = false;
            err.email = 'Please Enter Valid Email.';
        }
        if (!mobile) {
            validate = false;
            err.mobile = 'Please Enter Phone Number.';
        }
        if (!subject) {
            validate = false;
            err.subject = 'Please Enter Subject.';
        }
        if (!message) {
            validate = false;
            err.message = 'Please Enter Message.';
        }
        setError(err);
        if (validate) {
            let body = {
                fullName: name,
                email: email,
                subject: subject,
                message: message,
                mobile: mobile
            }
            axios.post(url + 'contact-us', body).then((res) => {
                if (res.data.status === 200) {
                    console.log("sfyhf", res)
                    console.log("fdsiuydfu", res.data.message);
                    setName(""); setEmail(""); setSubject(""); setMessage(""); setMessage("");
                }
            }).catch((err) => {
                console.log(err);
            })

        }
    }

    return (
        <div className="container">
            <div>
                <h2 data-aos="fade-up" data-aos-duration="2000" className="contact-form-heading">Contact Form</h2>
                <h3 data-aos="fade-up" data-aos-duration="3000" className="contact-form-description">Please leave your information below, Our representative will contact you during business hours</h3>
            </div>
            <div className="form-container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-3" data-aos="fade-up" data-aos-duration="1000" >
                        <input className='contact-form-input' placeholder='Full Name' value={name} onChange={(e) => { setName(e.target.value); }} />
                        {error && error?.name && <div className='error'>{error?.name}</div>}
                    </div>
                    <div className="col-md-6 col-sm-12 mb-3" data-aos="fade-up" data-aos-duration="1000">
                        <input className='contact-form-input' placeholder='Phone Number' value={mobile} onChange={(e) => { setMobile(e.target.value); }} />
                        {error && error?.mobile && <div className='error'>{error?.mobile}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-3" data-aos="fade-up" data-aos-duration="1000">
                        <input className='contact-form-input' placeholder='Email Address' value={email} onChange={(e) => { setEmail(e.target.value); }} />
                        {error && error?.email && <div className='error'>{error?.email}</div>}
                    </div>
                    <div className="col-md-6 col-sm-12 mb-3" data-aos="fade-up" data-aos-duration="1000">
                        <input className='contact-form-input' placeholder='Subject' value={subject} onChange={(e) => { setSubject(e.target.value); }} />
                        {error && error?.subject && <div className='error'>{error?.subject}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12  mb-3" data-aos="fade-up" data-aos-duration="1000">
                        <textarea className='contact-us-textarea' placeholder='Your Message' value={message} onChange={(e) => { setMessage(e.target.value); }} />
                        {error && error?.message && <div className='error'>{error?.message}</div>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' data-aos="fade-up" data-aos-duration="1000">
                        <button type='button' onClick={() => { handelSubmit() }} className='contact-btn'>Send Message</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactForm;